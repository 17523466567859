import ServicesTemplate from "../../components/ServicesTemplate";
import { ServicesProps } from "../../service";

const config: ServicesProps = {
  title: "Self-Managed Cloud Services",
  sectionHeader:
    "All the cloud services you need, with an intuitive cloud portal. Any time, any where.",
  sectionContent:
    "The goSystem Cloud Portal is the most comprehensive cloud service portal in the industry. Your virtual shopping centre for cloud services. From Microsoft 365 services, to cloud hosting solutions. With instant provisioning, the best prices on the market and it's easy to use interface, the goSystem Cloud Portal will become your go-to for all of your cloud service needs. ",
  splitHeading: "Industry-Standard Tools",
  splitContent: "Get everything you need, without the management.",
  splitTag:
    "Get the industry-standard tools you know and love, without the hassle of user and service management. goSystem provide the tools your employees need to maximise productivity without the management overhead. With 24/7 support, bulk user provisioning and migration services, goSystem take the hassle out of 365.",
  splitImg: "AWS",
  callToAction: "Start your cloud journey with goSystem today!",
};

export default () => ServicesTemplate(config);
